@import "../../styles/general";

.container {
  box-sizing: border-box;
  padding: 0 map_get($dimensions, l);
  position: relative;
  margin: 0 auto;
  z-index: 1;
}

@media only screen and (min-width: map_get($breakpoints, screen-md)) {
  .container {
    padding: 0 map_get($dimensions, m) * 2;
  }
}

@media only screen and (min-width: map_get($breakpoints, screen-xl)) {
  .container {
    padding: 0;
  }
}