@import "../../../../styles/general";

.desktop-nav {
  display: none;
}

@media only screen and (min-width: map_get($breakpoints, screen-lg)) {
  %desktop-nav-text {
    @include text-style(
            $color: map_get($colors, background),
            $font-family: $font-Raleway,
            $font-size: map_get($dimensions, sm),
            $font-weight: 500,
            $line-height: map_get($dimensions, base)
    );
  }

  .desktop-nav {
    display: flex;
    height: 100%;
  }

  .menu:hover {
    .menu-section {
      display: block;
    }
  }

  .menu-header {
    align-items: center;
    display: flex;
    color: transparentize(map_get($colors, background), .46);
    font-family: $font-Raleway;
    font-size: map_get($dimensions, sm);
    font-weight: 500;
    line-height: map_get($dimensions, base);
    height: 100%;
    padding: 0 map_get($dimensions, base);

    &:hover {
      @extend %desktop-nav-text;
      background-color: map_get($colors, foreground-dark-1);
      cursor: pointer;
    }
  }

  .menu-section {
    background-color: map_get($colors, foreground);
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);
    display: none;
    list-style: none;
    margin: -12px 0 0 0;
    padding: .5rem map_get($dimensions, base);
    position: absolute;
    min-width: 11.25rem;
  }

  .menu-header-link {
    @extend %desktop-nav-text;
    color: transparentize(map_get($colors, background), .46) !important;
    height: map_get($dimensions, l) * 2;
    text-decoration: none;
  }

  .menu-link {
    @extend %desktop-nav-text;
    align-items: center;
    display: flex;
    color: transparentize(map_get($colors, background), .46);
    height: map_get($dimensions, l) * 2;
    text-decoration: none;

    &:hover {
      background-color: map_get($colors, foreground-dark-1);
      color: map_get($colors, background);
      cursor: pointer;
      margin: 0 -16px;
      padding: 0 map_get($dimensions, base);
    }
  }

  .menu-link--active {
    position: relative;
    color: map_get($colors, primary);

    &:hover {
      color: map_get($colors, primary);
    }
    &:before {
      background: map_get($colors, primary);
      content: '';
      height: 2px;
      position: absolute;
      bottom: -10px;
      left: -8px;
      width: calc(100% + 16px);
    }
  }

  .menu-header-hover,
  .menu-header-active {
    .menu-header-text {
      color: map_get($colors, background);
      position: relative;

      &:before {
        background: map_get($colors, primary);
        content: '';
        height: 2px;
        position: absolute;
        bottom: -10px;
        left: -8px;
        width: calc(100% + 16px);
      }
    }
  }

}