@import "../../styles/general";

.header-content__logo {
  width: 206px;
  height: 48px;
  padding-top: map_get($dimensions, m) * 2;
  margin: 0;
}

.header-content__info-container {
  display: flex;
  justify-content: center;
}

.header-content__info {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.header-content__title {
  @include text-style(
    $color: transparentize( map_get($colors, foreground), .13),
    $font-family: $font-Raleway,
    $font-size: map_get($dimensions, xxl),
    $font-weight: 600,
    $line-height: map_get($dimensions, xxl)
  );
  margin: 0;
  padding-top: 6.5rem;
}

.header-content__description {
  @include text-style(
          $color: transparentize( map_get($colors, foreground), .13),
          $font-family: $font-Raleway,
          $font-size: 18px,
          $font-weight: 500,
          $line-height: 24px
  );
  margin: map_get($dimensions, m) 0;
}

.header-content__btn {
  @include primary-button();
  display: inline-block;
  cursor: pointer;
}

.header-content__player-container {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: map_get($dimensions, l) 0 map_get($dimensions, m) * 2;
}

.header-content__player-background {
  background-image: url(../../assets/img_repetable_bkg.png);
  background-size: contain;
  width: 90%;
  padding: 5% 0;
  min-height: 100px;
}
  
.header-content__player {
  margin-left: -2.5%;
  width: calc(100% + 5%);
}

@media only screen and (min-width: map_get($breakpoints, screen-md)) { //48rem ; 768px
  .header-content__description {
    font-size: map_get($dimensions, l);
    line-height: map_get($dimensions, xxl);
  }

  .header-content__player-background {
    // min-height: 335px;
  }
}

@media only screen and (min-width: map_get($breakpoints, screen-lg)) {
  .header-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: calc(100vh - 4.5rem);
    max-height: 750px;
  }

  .header-content__info-container {
    width: 50%;
    margin-top: map_get($dimensions, xxl) * 2.75;
    padding: 0;
  }

  .header-content__logo {
    padding-top: map_get($dimensions, xxl) * 2;
  }

  .header-content__title {
    padding-top: 0;
  }

  .header-content__description {
    width: 100%;
    margin: map_get($dimensions, xl) 0 map_get($dimensions, l);
    font-size: 18px;
    line-height: 24px;
  }

  .header-content__player-container {
    padding: map_get($dimensions, xxl) * 2.75 0;
    padding-bottom: 0;
    min-height: 362px;
    width: 65%;
    justify-content: flex-end;
  }

  .header-content__player {
    margin-left: -2rem;
    width: 100% ;
  }
}

@media only screen and (min-width: map_get($breakpoints, screen-xl)) {
  .header-content__logo {
    padding-top: map_get($dimensions, xxl) * 3;
  }

  // .header-content__title {
  //   padding-top: map_get($dimensions, xxl) * 3;
  // }
}