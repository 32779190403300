@import "../../styles/general";

.connatix-site-section {
    padding: 60px 20px;
    background-color: #fff;
    h2 {
        font-family: "Raleway";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        display: flex;
        align-items: center;
        margin: 0;
        margin-bottom: 16px;
    }
    p {
        font-family: "Raleway";
        line-height: 1.5rem;
        font-weight: 500;
        margin-bottom: 40px;
        z-index: 1;
    }

    a {
        font-family: "Raleway";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        align-items: center;
        text-decoration-line: underline;
        color: #4397f7;
        margin-bottom: 40px;
    }

    .navbar__demo-btn {
        border-radius: 1.25rem;
        font-family: "Raleway", sans-serif;
        font-size: 0.875rem;
        font-weight: 600;
        padding: 0.75rem 1.25rem;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        background-color: #0099ff;
        color: rgba(255, 255, 255, 0.87);
        display: inline-block;
        cursor: pointer;
    }

    img {
        width: 100%;
        height: 100%;
    }
}

@media only screen and (min-width: map_get($breakpoints, screen-md)) {
    .connatix-site-section {
        display: flex;
        align-items: center;
        justify-content: center;
        > div:first-of-type {
            margin-right: 47px;
        }
    }
}
