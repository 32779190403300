@import '../../styles/general';

.navbar {
  align-items: center;
  display: flex;
  background-color: map_get($colors, foreground);
  height: map_get($dimensions, l) * 3;
  justify-content: space-between;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99999;
}

.navbar-logo-container {
  align-items: center;
  display: flex;
  height: 100%;
}

.logo-container {
  width: 100px;
  display: flex;
  img {
    width: 100%;
  }
}

.navbar__menu-toggle {
  height: map_get($dimensions, base);
  padding: map_get($dimensions, xs);
  margin: 0 map_get($dimensions, xs);
}

.navbar-user-actions {
  margin: 5px map_get($dimensions, m) 0;
}

.navbar__login-btn {
  @include text-button();
  margin-right: map_get($dimensions, base) / 2;
}

.navbar__demo-btn {
  @include primary-button();
  display: none;
}

@media only screen and (min-width: 345px) {
  .logo-container {
    width: unset;
    display: flex;
  }
  
}

@media only screen and (min-width: map_get($breakpoints, screen-sm)) {
  .navbar__demo-btn {
    display: inline-block;
  }
}

@media only screen and (min-width: map_get($breakpoints, screen-lg)) {
  .navbar__menu-toggle {
    display: none !important;
  }

  .navbar-logo-container {
    margin-left: map_get($dimensions, base);
  }
}