@import '../../../../styles/general';

.body--overflow {
  position: relative;
  height: 100%;
  overflow: hidden;
}

.mobile-menu-container {
  background-color: map_get($colors, background-light-1);
  left: 0;
  bottom: 0;
  position: fixed;
  top: map_get($dimensions, l) * 3;
  width: 100%;
}

%navigation-link-style {
  @include text-style(
    map_get($colors, foreground),
    calc(map_get($dimensions, xl) / 2),
    $font-Raleway,
    600,
    1.75
  )
}

.mobile-menu {
  height: 100%;
  overflow: auto;
  padding: 0 map_get($dimensions, base);
}

.mobile-menu__header {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: map_get($dimensions, m) * 2;
  justify-content: space-between;
  padding-top: map_get($dimensions, xs);
}

.mobile-menu__header__text {
  @extend %navigation-link-style;
  opacity: .56;
  text-transform: uppercase;
}

.mobile-menu__header__expand-icon {
  color: map_get($colors, foreground);
  display: flex;
  flex-direction: column;

  i {
    font-size: map_get($dimensions, base);
    line-height: .5;
  }
}

.mobile-menu__header__expand-icon--closed {
  flex-direction: column-reverse;
}

.mobile-menu__section {
  border-bottom: 1px solid map_get($colors, background-light-3);
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0 0 map_get($dimensions, xs) 0;

  &:last-of-type {
    border-bottom: none;
  }
}

.mobile-menu__section--hidden {
  display: none;
}

.mobile-menu__link {
  @extend %navigation-link-style;
  align-items: center;
  display: flex;
  height: map_get($dimensions, m) * 2;
  padding: 0 map_get($dimensions, base);
  margin: 0 map_get($dimensions, base) * -1;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    background-color: map_get($colors, background-light-2);
  }

  &:focus {
    background-color: map_get($colors, background);
    color: map_get($colors, primary);
  }
}

.mobile-menu__link--active {
  background-color: map_get($colors, background-light-4);
  color: map_get($colors, primary);

  &:hover {
    color: map_get($colors, foreground);
  }
}

.mobile-demo-btn-container {
  height: 4rem;
}

.mobile-demo-btn {
  @include primary-button();
  bottom: map_get($dimensions, base);
  display: block;
}

@media only screen and(min-width: map_get($breakpoints, screen-sm)) {
  .mobile-demo-btn-container {
    display: none;
  }
}