@import "../../styles/general";

.common-questions {
    * {
        font-family: $font-Raleway;
    }
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 16px 120px 16px;

    h2 {
        text-align: center;
        line-height: map_get($dimensions, xxl);
        font-weight: 600;
        font-size: map_get($dimensions, l);
        margin: 0;
        margin-bottom: 16px;
        z-index: 1;
    }
    .subtitle {
        text-align: center;
        line-height: map_get($dimensions, l);
        font-weight: 500;
        margin-bottom: 40px;
        z-index: 1;

        .help-link {
            cursor: pointer;
            color: #4397f7;
            text-decoration: underline;
            font-weight: 600;
            margin-left: 4px;
        }
    }

    .common-card {
        width: 100%;
        max-width: 810px;

        margin-bottom: 16px;

        z-index: 1;

        &__top {
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 8px;
            border: 1px solid #d3dbdf;
            background-color: #fff;
            border-radius: 4px;
            h3 {
                margin: 0;
                line-height: 1.5rem;
                font-weight: 600;
                font-size: 15px;
                color: #0099ff;
            }
            span {
                display: flex;
                width: 32px;
                height: 32px;
            }
        }

        p {
            margin: 0;
        }

        &.visible {
            border: 1px solid #d3dbdf;
            border-radius: 4px;
            background-color: white;
            .common-card__top {
                border: 1px solid transparent;
                border-top: none;
            }
            .answer {
                display: block;
                font-weight: 500;
                margin: 0;
                padding: 0 8px 16px;
                line-height: 18px;
                font-size: 13px;
                p + p {
                    margin-top: 16px;
                }
            }
        }

        &.hidden {
            min-height: map_get($dimensions, xl);
            background: map_get($colors, foreground);

            .answer {
                display: none;
            }
        }
    }

    .decoration {
        position: absolute;
        bottom: 0;
        right: 16px;
        width: 196px;
    }

    .read-more-btn-container {
        width: 100%;
        max-width: 800px;
        margin: 0 auto;
    }

    .read-more-btn {
        padding: 12px;
        text-transform: uppercase;
        border-radius: 8px;
        background: #0099ff;
        color: white;
        border: none;
        font-weight: 600;
        font-size: 14px;
        letter-spacing: -0.02em;
        cursor: pointer;
        margin-top: 4px;
    }
}

@media only screen and (min-width: map_get($breakpoints, screen-sm)) {
    //576px
    .common-questions {
        padding: 64px 32px;
        .decoration {
            right: 32px;
            width: 264px;
        }
        h2 {
            line-height: 38px;
            font-size: map_get($dimensions, xxl);
        }
        .common-card__top {
            h3 {
                font-size: 18px;
                line-height: 22px;
            }
        }
        .common-card {
            &.visible {
                .answer {
                    line-height: 16px;
                    font-size: 13px;
                }
            }
        }
    }
}

@media only screen and (min-width: map_get($breakpoints, screen-md)) {
    //768px
    .common-questions {
        padding: 80px 56px;
        .decoration {
            right: 96px;
            width: 352px;
        }

        .common-card {
            &.visible {
                .answer {
                    line-height: 18px;
                    font-size: 14px;
                }
            }
        }
    }
}

@media only screen and (min-width: map_get($breakpoints, screen-lg)) {
    //992px
    .common-questions {
        padding: 120px 24px;
        max-width: 800px;
        margin: 0 auto;
        .decoration {
            right: 96px;
        }
    }
}
